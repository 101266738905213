<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Items from "@/components/projects/items";

import {
	projectMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
	title: "Items",
	meta: [
		{
			name: "description",
			content: appConfig.description,
		},
	],
  },
  components: {
	Layout,
	PageHeader,
	Items
  },
  data() {
	return {
		title: this.$t('events.title'),
		items: [],
        project:{},
        
    }
  },
  
  mounted() {
    if(localStorage.getItem("current_project")){
        this.loadProject(localStorage.getItem("current_project"))
    }
},
  created() {
	
  },
  methods: {
	...projectMethods,
    
    loadProject(id){
      let loader = this.$loading.show();

      this.getProject(id).then((res)=>{
        this.project = res.data;
      }).catch(()=>{

      }).finally(()=>{
        loader.hide()
      })
    },
}
};
</script>

<template>
	<Layout>
		<PageHeader title="Items" :items="items" />
		<div class="">
        <Items :project="project"></Items>
		</div>
        
	</Layout>
</template>

<style scoped>
.interact{
	cursor: pointer;
  }

.bold{
	font-weight: bold;
}
</style>